import React from 'react';
import { graphql, Link } from 'gatsby';
import SEO from '../components/SEO';
import Layout from '../components/Layout';

const Team = (props) => {
  const team = props.data.team.edges;
  const { intro } = props.data;

  return (
    <Layout bodyClass="page-team-single">
      <SEO
        title="Team"
        location={props.location}
        description={intro.frontmatter.summary}
      />

      <div className="container pb-6 pt-6 pt-md-10 pb-md-10">
        <div className="row justify-content-start">
          <div className="col-12 col-md-8">
            <h1 className="title">{intro.frontmatter.title}</h1>
            <div
              className="content"
              dangerouslySetInnerHTML={{ __html: intro.html }}
            />
          </div>
        </div>

        <div className="container">
          <div className="row">
            {team
              .filter((edge) => edge.node.frontmatter.promoted)
              .map(({ node }) => (
                <div key={node.id} className="col-12 col-md-6 mb-2">
                  <div className="team team-summary team-summary-large">
                    {node.frontmatter.image && (
                      <div className="team-image">
                        <Link
                          to={`/team/${node.frontmatter.title.toLowerCase()}`}
                        >
                          <picture>
                            <source
                              srcSet={node.frontmatter.image_optimized}
                              type="image/webp"
                            />
                            <source
                              srcSet={node.frontmatter.image}
                              type="image/jpeg"
                            />
                            <img
                              alt={`photo of ${node.frontmatter.title}`}
                              className="img-fluid mb-2"
                              src={node.frontmatter.image}
                            />
                          </picture>
                        </Link>
                      </div>
                    )}
                    <div className="team-meta">
                      <Link
                        to={`/team/${node.frontmatter.title.toLowerCase()}`}
                      >
                        <h2 className="team-name">{node.frontmatter.title}</h2>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query TeamQuery {
    team: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/team/.*/" } }
      sort: { fields: [frontmatter___title], order: ASC }
    ) {
      edges {
        node {
          id
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            promoted
            image
            image_optimized
            jobtitle
            linkedinurl
            summary
          }
        }
      }
    }
    intro: markdownRemark(fileAbsolutePath: { regex: "/(team.md)/" }) {
      html
      frontmatter {
        image
        intro_image
        intro_image_absolute
        intro_image_hide_on_mobile
        title
        summary
      }
    }
  }
`;

export default Team;
